import { graphql } from "gatsby"
import React from "react"
import Section from "../Section/Section"
import * as styles from "./video.module.scss"
import Button from "../../01_atoms/Button/Button"
import { Heading } from "../../01_atoms/Heading/Heading"
import Spacer from "../../01_atoms/Spacer/Spacer"

export default function Video(props) {
  console.log(props)
  return (
    <div className={styles.video}>
      <Section background="white" content>
        {props.field_title && (
          <Heading element="h3" spacing="xl" align="center" size="l">
            {props.field_title}
          </Heading>
        )}

        <iframe
          src={`https://www.youtube.com/embed/${props.field_youtube_id}?rel=0&modestbranding=1`}
          width="1920"
          height="1080"
          allow="autoplay; fullscreen; picture-in-picture"
          title="Streamz Academy | Trailer"
          frameBorder="0"
          className={styles.iframe}
        />

        {props.relationships.field_cta?.length > 0 && (
          <div className={styles.buttons}>
            {props.relationships.field_cta.map(
              (cta, index) =>
                cta.field_link?.url &&
                cta.field_link?.title && (
                  <div key={index}>
                    <Button key={index} type="link" to={cta.field_link?.url}>
                      {" "}
                      {cta.field_link?.title}
                    </Button>
                    {props.relationships.field_cta.length > 1 && (
                      <Spacer height={5} />
                    )}
                  </div>
                )
            )}
          </div>
        )}
      </Section>
    </div>
  )
}

export const query = graphql`
  fragment videoFragment on paragraph__video {
    __typename
    internal {
      type
    }
    id
    field_title
    field_youtube_id
    relationships {
      field_cta {
        ...ctaFragment
      }
    }
  }
`
