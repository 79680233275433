import * as React from "react"
import { Scroll } from "scrollex"
import * as styles from "./introanimation.module.scss"
import cx from "classnames"
import DEFAULT_SPRING_CONFIGS from "../../../../config/springs"

const keyframes_block1 = {
  product: ({ section }) => ({
    [section.topAt("container-top")]: {
      translateY: "0%",
      skewX: "0deg",
      skewY: "0deg",
    },
    [section.height]: {
      skewX: "-5deg",
      skewY: "5deg",
      translateY: "10%",
    },
  }),
  bg: ({ section }) => ({
    [section.topAt("container-top")]: {
      skewX: "15deg",
      backgroundColor: "#ddb8a6",
      translateX: "0",
    },
    [section.height * 0.25]: {
      skewX: "-15deg",
      backgroundColor: "#E6CD96",
    },
    [section.height * 1.5]: {
      skewX: "-15deg",
      translateX: "0%",
    },
    [section.height * 2]: {
      skewX: "15deg",
      translateX: "-100%",
    },
  }),
  bg_mobile: ({ section }) => ({
    [section.topAt("container-top")]: {
      skewY: "15deg",
      backgroundColor: "#ddb8a6",
      translateX: "0",
    },
    [section.height]: {
      skewY: "-15deg",
      backgroundColor: "#E6CD96",
    },
  }),
}

const keyframes_block2 = {
  product: ({ section }) => ({
    [section.topAt("container-top")]: {
      translateX: "10%",
      translateY: "-10%",
      skewX: "0deg",
      skewY: "0deg",
    },
    [section.height]: {
      skewX: "-5deg",
      skewY: "5deg",
      translateX: "0%",
      translateY: "0%",
    },
  }),
}

const keyframes_block3 = {
  product: ({ section }) => ({
    [section.height * 1]: {
      translateY: "-20%",
      skewX: "0deg",
      skewY: "0deg",
    },
    [section.height * 3]: {
      skewX: "-10deg",
      skewY: "10deg",
      translateY: "0%",
    },
  }),
  bg: ({ section }) => ({
    [section.height * 1]: {
      backgroundColor: "#979f8a",
      translateX: "100%",
      skewX: "15deg",
    },
    [section.height * 2]: {
      backgroundColor: "#979f8a",
      translateX: "0%",
    },
    [section.height * 2]: {
      backgroundColor: "#979f8a",
      translateX: "0%",
    },
  }),
}

export const IntroBlock1 = ({ children }) => (
  <Scroll.Section className={styles.wrapper}>
    <Scroll.Item
      className={styles.background_block1}
      keyframes={keyframes_block1.bg}
      springs={DEFAULT_SPRING_CONFIGS}
    />
    <Scroll.Item
      className={styles.background_block3}
      keyframes={keyframes_block3.bg}
      springs={DEFAULT_SPRING_CONFIGS}
    />
    <Scroll.Item
      className={styles.product_block1}
      keyframes={keyframes_block1.product}
      springs={DEFAULT_SPRING_CONFIGS}
    />

    {/* Mobile */}

    <Scroll.Item
      className={cx(
        styles.background_block_mobile,
        styles.background_block_mobile_1
      )}
      keyframes={keyframes_block1.bg_mobile}
      springs={DEFAULT_SPRING_CONFIGS}
    />

    <Scroll.Item
      className={cx(styles.product_block_mobile, styles.product_block_mobile_1)}
      keyframes={keyframes_block1.product}
      springs={DEFAULT_SPRING_CONFIGS}
    />

    {children}
  </Scroll.Section>
)

export const IntroBlock2 = ({ children }) => (
  <Scroll.Section className={styles.wrapper}>
    <Scroll.Item
      className={styles.product_block2}
      keyframes={keyframes_block2.product}
      springs={DEFAULT_SPRING_CONFIGS}
    />

    {/* Mobile */}

    <div
      className={cx(
        styles.background_block_mobile,
        styles.background_block_mobile_2
      )}
    />

    <div
      className={cx(styles.product_block_mobile, styles.product_block_mobile_2)}
    />

    {children}
  </Scroll.Section>
)

export const IntroBlock3 = ({ children }) => (
  <Scroll.Section className={styles.wrapper}>
    <Scroll.Item
      className={styles.product_block3}
      keyframes={keyframes_block3.product}
      springs={DEFAULT_SPRING_CONFIGS}
    />

    {/* Mobile */}

    <div
      className={cx(
        styles.background_block_mobile,
        styles.background_block_mobile_3
      )}
    />

    <div
      className={cx(styles.product_block_mobile, styles.product_block_mobile_3)}
    />

    {children}
  </Scroll.Section>
)
