// extracted by mini-css-extract-plugin
export var background_block1 = "introanimation-module--background_block1--uNu-j";
export var background_block3 = "introanimation-module--background_block3--QTT3l";
export var background_block_mobile = "introanimation-module--background_block_mobile--DbolX";
export var background_block_mobile_1 = "introanimation-module--background_block_mobile_1--ffjmE";
export var background_block_mobile_2 = "introanimation-module--background_block_mobile_2--frn2e";
export var background_block_mobile_3 = "introanimation-module--background_block_mobile_3--Jn3PW";
export var product_block1 = "introanimation-module--product_block1--xd119";
export var product_block2 = "introanimation-module--product_block2--xMSSP";
export var product_block3 = "introanimation-module--product_block3--P2+aE";
export var product_block_mobile = "introanimation-module--product_block_mobile--b-252";
export var product_block_mobile_1 = "introanimation-module--product_block_mobile_1--CBAZa";
export var product_block_mobile_2 = "introanimation-module--product_block_mobile_2--ZFrQP";
export var product_block_mobile_3 = "introanimation-module--product_block_mobile_3--i0MPc";
export var wrapper = "introanimation-module--wrapper--JrNoj";