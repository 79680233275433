import { graphql } from "gatsby"
import * as React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"

import Button from "../components/01_atoms/Button/Button"
import {
  IntroBlock1,
  IntroBlock2,
  IntroBlock3,
} from "../components/03_organisms/animations/IntroAnimation/IntroAnimation"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/03_organisms/Modal/Modal"
import { useState } from "react"
import Video from "../components/03_organisms/Video/Video"

const IndexPage = ({ location, data }) => {
  const homepageData = data.allNodeHomepage.nodes[0]
  const animatedBlocks = homepageData.relationships.field_animated_blocks

  const [modalOpen, setModalOpen] = useState(true)

  return (
    <Layout>
      <Seo
        title={homepageData.field_meta_title}
        description={homepageData.field_meta_description}
      />

      {homepageData && (
        <>
          {/* Hero Block */}
          <ImageTextBlock
            height="100"
            image_position="left"
            animation={<IntroBlock1 />}
            className="hero"
          >
            <Heading size="s" spacing="m">
              {homepageData.field_baseline?.processed}
            </Heading>
            <Heading size="xxxl" spacing="l">
              {homepageData.title}
            </Heading>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{
                  __html: homepageData.body.processed,
                }}
              />
            </Paragraph>
          </ImageTextBlock>

          <ImageTextBlock
            image_position={animatedBlocks[0].field_image_position}
            animation={<IntroBlock2 />}
            background_color={
              animatedBlocks[0].relationships.field_background_color?.name
            }
            height={animatedBlocks[0].field_blocks_height}
          >
            <Heading size="xl" spacing="l">
              {animatedBlocks[0].field_title}
            </Heading>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{
                  __html: animatedBlocks[0].field_content.processed,
                }}
              />
            </Paragraph>
            <Button
              type="link"
              to={animatedBlocks[0].relationships.field_cta[0].field_link.url}
            >
              {animatedBlocks[0].relationships.field_cta[0].field_link.title}
            </Button>
          </ImageTextBlock>

          <ImageTextBlock
            image_position={animatedBlocks[1].field_image_position}
            animation={<IntroBlock3 />}
            background_color={
              animatedBlocks[1].relationships.field_background_color?.name
            }
            height={animatedBlocks[1].field_blocks_height}
          >
            <Heading size="xl" spacing="l">
              {animatedBlocks[1].field_title}
            </Heading>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{
                  __html: animatedBlocks[1].field_content.processed,
                }}
              />
            </Paragraph>
            <Button
              type="link"
              to={animatedBlocks[1].relationships.field_cta[0].field_link.url}
            >
              {animatedBlocks[1].relationships.field_cta[0].field_link.title}
            </Button>
          </ImageTextBlock>

          {/* Bottom Block */}

          {homepageData.relationships.field_components.map((item, index) => {
            switch (item.internal.type) {
              case "paragraph__image_text":
                return (
                  <ImageTextBlock
                    key={index}
                    image_position={item?.field_image_position}
                    image={item.relationships.field_image}
                    background_color={
                      item.relationships.field_background_color?.name
                    }
                    height={item.field_blocks_height}
                  >
                    <Heading size="xl" spacing="l">
                      {item.field_title}
                    </Heading>
                    <Paragraph>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.field_content.processed,
                        }}
                      />
                    </Paragraph>

                    {item.relationships.field_cta[0] && (
                      <Button
                        type="link"
                        to={item.relationships.field_cta[0].field_link.url}
                      >
                        {item.relationships.field_cta[0].field_link.title}
                      </Button>
                    )}
                  </ImageTextBlock>
                )
              case "paragraph__video":
                return <Video {...item} key={index}></Video>
              default:
                return <div>Invalid component</div>
            }
          })}
        </>
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeHomepage(
      filter: { langcode: { eq: $language }, status: { eq: true } }
    ) {
      nodes {
        body {
          processed
        }
        drupal_internal__nid
        field_animated_blocks {
          drupal_internal__target_id
        }
        field_baseline {
          processed
        }
        path {
          alias
        }
        field_meta_description
        field_meta_title
        relationships {
          field_animated_blocks {
            ...imageTextFragment
          }
          field_components {
            ... on Node {
              ...imageTextFragment
              ...videoFragment
            }
          }
        }
        title
      }
    }
  }

  fragment imageFragment on media__image {
    field_media_image {
      alt
      drupal_internal__target_id
    }
    internal {
      type
    }
    relationships {
      field_media_image {
        image_style_uri {
          original_png
          original_webp
          extra_wide_png
          extra_wide_webp
          landscape_png
          landscape_webp
          portrait_png
          portrait_webp
          square_png
          square_webp
        }
        url
      }
    }
  }

  fragment imageTextFragment on paragraph__image_text {
    drupal_internal__id
    field_title
    field_blocks_height
    field_content {
      processed
    }
    field_cta {
      drupal_internal__target_id
    }
    field_image {
      drupal_internal__target_id
    }
    field_image_position
    field_image_size
    field_fullwidth
    internal {
      type
    }
    relationships {
      field_cta {
        ...ctaFragment
      }
      field_image {
        ...imageFragment
      }
      field_background_color {
        name
        internal {
          type
        }
      }
    }
  }

  fragment ctaFragment on paragraph__cta {
    id
    internal {
      type
    }
    field_link {
      title
      url
      uri
    }
  }

  fragment paragraph__card_collectionFragment on paragraph__card_collection {
    __typename
    id
    field_content {
      processed
    }
    internal {
      type
    }
    field_title
    relationships {
      field_background_color {
        name
      }
      field_cards {
        field_content {
          processed
        }
        field_title
        relationships {
          field_background_color {
            name
          }
          field_image {
            ...imageFragment
          }
        }
      }
      field_cta {
        ...ctaFragment
      }
    }
  }
`
